import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div class="footer-main">
        <div class="footer-area footer-padding">
          <div class="container">
            <div class="row  justify-content-between">
              <div class="col-lg-3 col-md-4 col-sm-8">
                <div class="single-footer-caption mb-30">
                  {/* <div class="footer-logo">
                    <a href="/">
                      <img src="assets/img/logo/logo2_footer.png" alt="" />
                    </a>
                  </div> */}
                  <div class="footer-tittle">
                    <div class="footer-pera">
                      <h4>Mission</h4>
                      <p class="info1">
                      At PassIt we believe in making gyms accessible to everyone. <br/><br/>
                      We do this by negotiating with gym owners  in order to provide exclusive discounts and services to our members.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
              {/* <div class="col-lg-2 col-md-4 col-sm-5">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Quick Links</h4>
                    <ul>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Features</a>
                      </li>
                      <li>
                        <a href="#">Pricing</a>
                      </li>
                      <li>
                        <a href="#">Download</a>
                      </li>
                      <li>
                        <a href="#">Reviews</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div class="col-lg-2 col-md-4 col-sm-7">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Terms & Conditions</a>
                      </li>
                      {/* <li>
                        <a href="#">FAQs</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-5">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Headquarters</h4>
                    <div class="footer-pera footer-pera2"><p>Spokane, Washington</p></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-8">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Contact</h4>
                    <div class="footer-pera footer-pera2">
                      <p>
                        Feel free to reach out at any time with any questions or concerns. <br/><br/>
                        We can be reached at <b>info@getpassit.com</b> and will typically get back to you within one business day.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Copyright */}
            <div class="row align-items-center">
              <div class="col-xl-12 ">
                <div class="footer-copy-right">
                  <p>
                    Copyright &copy;
                    <script>document.write(new Date().getFullYear());</script> {" "}
                    PASSIT
                  </p>
                </div>
              </div>
            </div>
            {/* end of Copyright */}

          </div>
        </div>
      </div>
    </footer>
  );
}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/pages/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { Suspense, useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "./components/MetronicSplashScreen/MetronicSplashScreen";
import { Logout, AuthPage } from "./components/Auth";
import ErrorsPage from "./components/Errors/ErrorsPage";
import { HomePage } from "./components/HomeLanding/HomePage";
import { HowItWorks } from "./components/HowItWorksLanding/HowItWorks";
import { GymPage } from "./components/GymLanding/GymPage";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Users } from "./components/Users/Users";
import { AppContext, useAppContext } from "./contexts/AppContext";
import { UserEditDialog } from "./components/Users/EditUser/UserEditDialog";
import { Gym } from "./components/Gym/Gym";


export function Routes() {
  const token = window.localStorage.token;
  const { state, dispatch } = useAppContext()
  return (
    // <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        {console.log('boo', state)}
        <Route exact path="/" render={rProps => token !== null ? <HomePage /> : <Redirect exact from="/" to="/dashboard"/> }  />
        <Route exact path="/how-it-works" component={HowItWorks} />
        <Route exact path="/gym" component={GymPage} />

        <Route path="/auth" component={AuthPage} />
        <Route exact path="/users" component={Users} />

        <Route path="/dashboard" component={Dashboard}/>
        {/* <Route path="/dashboard" component={RequireAuth(<Layout><BasePage/></Layout>)} /> */}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        <Route path="/gyms" component={Gym} />
        

        <Redirect to="error/error-v1"/>
      </Switch>

    // </Suspense>
  );
}

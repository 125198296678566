

export const appReducer = (state, action) => {
    switch (action.type) {
        case 'LOGINUSER':
            console.log('here', action.payload)
            return { ...state, currentUser: action.payload, cheese: 'yes' };
            break
        default:
            return state;
    }
}
/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { Suspense, useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../../Routes";
import { I18nProvider } from "./../../services/i18n";
import { LayoutSplashScreen } from "./../MetronicSplashScreen/MetronicSplashScreen";
import { MaterialThemeProvider } from "./../../contexts/MaterialThemeProvider";
import { AppProvider, AppContext, useAppContext } from "./../../contexts/AppContext";
import firebase from "./../../services/firebase";
import { useFirebaseAuth } from "../../hooks/useFirebaseAuth";

export default function App({ basename }) {

  const { state, dispatch } = useAppContext();
  // const user = useFirebaseAuth(firebase);
  
  // console.log('hmm', user != null && user.toJSON())
  return (
    // <PersistGate loading={<LayoutSplashScreen />
    //Add high level `Suspense` in case if was not handled inside the React tree.
    <AppProvider>
      {console.log(state, 'see')}
      <Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              <Routes />
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </Suspense>
    </AppProvider>
    //</PersistGate>
  );
}

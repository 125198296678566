import React from "react";

import FlipCountdown from "@rumess/react-flip-countdown";

export const CountDownTimer = () => {
  return (
    <FlipCountdown
      size="medium"
      theme="dark"
      titlePosition="bottom"
      endAt={"2020-12-31 05:00:00"} //YYYY-MM-DD
    />
  );
}

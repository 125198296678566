import React, { useContext, useEffect, useState, Fragment } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../Layout/Card";
import { UserFilter } from "./UserFilter";
import { UserTable } from "./UserTable";
import { UserGrouping } from "./UserGrouping";
import { UserContext, UserProvider } from "../../contexts/UserContext";
import { useFirebaseAuth } from "../../hooks/useFirebaseAuth";
import { UserEditForm } from "./EditUser/UserEditForm";
// import Modal from '@material-ui/core/Modal';
import { useHistory } from "react-router-dom";
import { UserEditDialog } from "./EditUser/UserEditDialog";
import { AppContext } from "./../../contexts/AppContext";
import { getRequest } from "./../../services/api";
import firebase from "./../../services/firebase";
import { FormattedMessage } from "react-intl";


export const UsersList = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const newCustomerButtonClick = () => {
    if (history !== undefined)   {
      // history.push('/users/new');
      handleOpen()
    }
    // history.push('/users/new')
  }

  const appContext = useContext(AppContext);
  const authUser = useFirebaseAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState('');
  const [status, setStatus] = useState(false)

  useEffect( () => {
    console.log(authUser)
      const getGyms = async (uid) => {
        console.log('yo')
        const gyms = await firebase.firestore().collection('gyms')
        .where('admin', '==', uid)
        .get()
        
        if (gyms.empty) {
          return {};
        }
  
        console.log(gyms)
        console.log(gyms.docs[0].id)
        console.log(gyms.docs[0].data());
  
        if (gyms.docs.length > 0) {
          getRequest(`/getCompanyUsers?companyId=${gyms.docs[0].id}`)
          .then(response => response.json())
      .then(body => {
        setUsers(body.users)
        console.log('users', body)
        setLoading(false)
        setCompanyId(gyms.docs[0].id)
      })
        }
        // gyms.forEach( doc => {
        //   doc.dat
        // })
 
  
    }
    
    if (authUser !== null) {
      getGyms(authUser.uid)
      }
  }, [authUser, status])

  return (
    <Fragment>
       {status &&
          <div className="mb-10 alert alert-custom alert-success alert-dismissible">
            <div className="alert-text font-weight-bold">{status}</div>
            <FormattedMessage id={status} />
          </div>
        }
      <Card>
        <CardHeader title="Users">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={newCustomerButtonClick}
            >
              New Customer
          </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <UserFilter /> */}
          {/* {customersUIProps.ids.length > 0 && <UserGrouping />} */}
          <UserTable users={users} isLoading={isLoading} />
        </CardBody>
      </Card>
      { open && <UserEditDialog show={open} onHide={handleClose} type={"new"} companyId={companyId} setStatus={setStatus} />}
    </Fragment>
  );
}

import React from "react";
// import { Link } from "react-router-dom";

export const HowItWorks = () => {
  return (
    <>
      {/* <Header currentPage="how-it-works" />

      <main>

      </main>

      <Footer /> */}
    </>
  )
}

import React, {createContext, useReducer} from "react";
import { userReducer } from './../reducers/UserReducer';

const initialState = { users: [] }
const UserContext = createContext(initialState);
 
const { Provider } = UserContext

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { UserContext, UserProvider }

import React from "react";
import { Link } from 'react-router-dom';

export const Header = (props) => {
  return (
    <header>
      {/* Header Start */}
      <div className="header-area header-transparrent ">
        <div className="main-header header-sticky">
          <div className="container">
            <div className="row align-items-center">
              {/* Logo */}
              <div className="col-xl-2 col-lg-2 col-md-2">
                <div className="logo">
                  <a href="/">
                    {/* <img src="assets/img/logo/logo.png" alt="" /> */}
                    <h1>PASSIT</h1>
                  </a>
                </div>
              </div>
              <div class="col-xl-10 col-lg-10 col-md-10">
                {/* Main-menu */}
                <div class="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li className={props.currentPage === "home" && "active"}>
                        <Link to="/">Home</Link>
                      </li>
                      {/* <li className={props.currentPage === "how-it-works" && "active"}>
                        <Link to="/how-it-works">How It Works</Link>
                      </li> */}
                      <li className={props.currentPage === "gym" && "active"}>
                        <Link to="/gym">Gym Sign Up</Link>
                      </li>
                      {props.currentPage === 'gym' &&
                        <li>
                          <Link to="/auth">Sign In</Link>
                        </li>
                      }
                      {/* <li>
                        <a href="#">Pages</a>
                        <ul class="submenu">
                          <li>
                            <a href="blog.html">Blog</a>
                          </li>
                          <li>
                            <a href="single-blog.html">Blog Details</a>
                          </li>
                          <li>
                            <a href="elements.html">Element</a>
                          </li>
                        </ul>
                      </li> */}

                    </ul>
                  </nav>
                </div>
              </div>
              {/* <!-- Mobile Menu --> */}
              <div class="col-12">
                <div class="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}
    </header>
  );
}

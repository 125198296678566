import React, { useEffect, useMemo, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { UserEditDialogHeader } from "./UserEditDialogHeader";
import UserEditForm from "./UserEditForm";
import { UserContext } from "../../../contexts/UserContext";

import { postRequest } from "./../../../services/api";
import firebase from "./../../../services/firebase";
import { useFirebaseAuth } from "./../../../hooks/useFirebaseAuth";

export const UserEditDialog = ({ id, show, onHide, type, companyId, setStatus }) => {
  // Customers UI Context
  const { state, dispatch } = useContext(UserContext);
  const [user, setUser] = useState({});
  const [actionsLoading, setActionsLoading] = useState(false);
  const [userForEdit, setUserForEdit] = useState(false);
  const [title, setTitle] = useState('')

 useEffect( () => {
  if (type === "new") {
    setTitle('New User')
  }

 }, [type])
  // const customersUIProps = useMemo(() => {
  //   return {
  //     initCustomer: customersUIContext.initCustomer,
  //   };
  // }, [customersUIContext]);

  // Customers Redux state

  console.log("ahahaha")
  const authUser = useFirebaseAuth();



  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <UserEditDialogHeader 
      title={title}
      />
      {console.log("bit", user)}
      <UserEditForm
        // saveUser={saveUser}
        actionsLoading={actionsLoading}
        user={userForEdit}
        onHide={onHide}
        companyId={companyId}
        setStatus={setStatus}
      />
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { Layout } from './../Layout/Layout';
import { Sidebar } from '../Sidebar/Sidebar';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "./../Layout/Card";
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';
import firebase from './../../services/firebase';
import { getRequest } from '../../services/api';

export const Gym = () => {

    const authUser = useFirebaseAuth();
    const [gyms, setGyms] = useState([]);

    useEffect(() => {
        console.log(authUser)
        const getGyms = async (uid) => {
            getRequest(`/getGymsForDashboard`)
                .then(response => response.json())
                .then(body => {
                    setGyms(body.gyms)
                    console.log(body)
                })
        }

        if (authUser !== null) {
            getGyms(authUser.uid)
        }
    }, [authUser])

    return (
        <Layout>
            <div>
                <Sidebar />
                <div
                    id="kt_content"
                    className={`content  d-flex flex-column flex-column-fluid`}
                >
                    <div className="gyms-layout">
                        {gyms.map(gym => {
                            return <Card className="gym-card">
                                <CardBody>
                                    <h2>{gym.name}</h2>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/passit-cc317.appspot.com/o/istock_000043549260_small.jpg?alt=media&token=201a0275-5d78-4086-9bbe-3c8a70d3580c" />
                                    <hr></hr>
                                    <div><span class="title">Type:</span> {gym.type}</div><br />
                                    {
                                        console.log(gym.address)
                                    }
                                    <div><span class="title">Location:</span> {gym.location.address.street}, {gym.location.address.city}, {gym.location.address.state}, {gym.location.address.zipcode}</div><br />
                                    <div><span class="title">Classes:</span>
                                            {gym.classes.map((gClass, index) => {
                                                return <span>{gClass}{index != gym.classes.length - 1 && ", "}</span>
                                            })}
                                    </div><br />
                                    <div><span class="title">Amenities:</span>
                                            {gym.amenities.map((amen, index) => {
                                                return <span>{amen}{index != gym.amenities.length - 1 && ", "}</span>
                                            })}
                                    </div>
                                </CardBody>
                            </Card>

                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDnzVPcutPin491lQbgdwoLZLPsGOIWQ7I",
  authDomain: "passit-cc317.firebaseapp.com",
  databaseURL: "https://passit-cc317.firebaseio.com",
  projectId: "passit-cc317",
  storageBucket: "passit-cc317.appspot.com",
  messagingSenderId: "712256015480",
  appId: "1:712256015480:web:c98f8cd52616fe7bd9b18d",
  measurementId: "G-8F04XYVHFC"
};

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  firebase.auth.signInWithPopup(provider);
};

firebase.initializeApp (firebaseConfig);
firebase.auth()

export default firebase;
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "./ModalProgressBar";

export function UserEditDialogHeader({ title}) {
  // Customers Redux state

  console.log('user')
  return (
    <>
      {/* {actionsLoading && <ModalProgressBar />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}

import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import firebase from "firebase";
import { postRequest } from "./../../services/api";
import Swal from "sweetalert2";

function Registration(props) {
  // const { handleSignup } = useContext(firebaseAuth);
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const pwdRegExp = "(?-i)(?=^.{8,}$)((?!.*\s)(?=.*[A-Z])(?=.*[a-z]))(?=(1)(?=.*\d)|.*[^A-Za-z0-9])^.*$";

  const formik = useFormik({
    initialValues: {
      facilityName: "",
      street: "",
      city: "",
      state: "",
      numberOfGyms: "",
      curDayPassPrice: "",    
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      referalCode: "",
    },
    validationSchema: Yup.object().shape({
      facilityName: Yup.string()
        .required("Facility Name required"),
      street: Yup.string()
        .required("Street required"),
      city: Yup.string()
        .required("City required"),
      state: Yup.string()
        .required("State required"),
      numberOfGyms: Yup.number()
        .typeError("Must be a number")
        .positive("Must be greater than zero")
        .required("Number of Gyms required"),
      curDayPassPrice: Yup.number()
        .positive("Must be greater than zero")
        .typeError("Must be a number")
        .required("Current Day Pass Price required"),
      firstName: Yup.string()
        .required("First Name required"),
      lastName: Yup.string()
        .required("Last Name required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address required"),
      phoneNumber: Yup.string()
        // .matches(phoneRegExp, 'Phone number is not valid.')
        .min(10, "Must be a 10 digits number")
        .required("Phone Number required"),
      password: Yup.string()
        // .matches(pwdRegExp, 'Password must be 8 or more characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number or 1 special character')
        .required('Password must be 8 or more characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number or 1 special character'),
      confirmPassword: Yup.string()
        .required("Passwords must match")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwords do not match"
          ),
        }),
      acceptTerms: Yup.bool(),// .required("You must accept the terms and conditions"),
      referalCode: Yup.string().required("Please enter referal code given")
    }),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      enableLoading()

      const data = {
        name: values.facilityName, 
        numberOfGyms: values.numberOfGyms, 
        address: {
          street: values.street,
          city: values.city, 
          state: values.state
        },
        referalCode: values.referalCode,
        curDayPassPrice: values.curDayPassPrice
      }
      
      try { // Create user using firebase SDK
        await createUser(values, { setStatus, setSubmitting })
        await createFirestoreEntry(values)
        await sendEmailVerification();

        await postRequest('/createGym', data)

        disableLoading()
        setSubmitting(false)
        Swal.fire({
          title: 'Sign Up Successful!',
          icon: 'success',
        }).then((result) => {
          if (result.isConfirmed) {
            props.history.push("/auth/login")  
          } else {
            props.history.push("/auth/login")  
          }
        })
       

      } catch (err) {
        disableLoading()
        setSubmitting(false)
        setStatus(intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN"}))
        console.log(err)
      }      
      
    }
  });

  const createUser = async (values, {setStatus, setSubmitting}) => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(values.email, values.password)   
    } catch(err) {
      console.log("Error creating user: ", err)
    }
    
    try {
      await firebase.auth().currentUser.updateProfile({displayName: `${values.firstName} ${values.lastName}`})
    } catch (err){
      console.log("Error updating user profile: ", err)
    }   
  }

  const createFirestoreEntry = async (values) => {
    try {
      await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
        email: values.email,
        firstname: values.firstName,
        lastname: values.lastName,
        telephone: values.phoneNumber,
        status: 'Active',
        createdAt: Date.now(),
      })
    } catch(err){
      console.error("Error adding document: ", err)
      
      // delete firebase user
      await firebase.auth().deleteUser(firebase.auth().currentUser.uid)
      .catch(err => console.error("Error removing user: ", err))
    }
  }

  const sendEmailVerification = async () => {
    await firebase.auth().currentUser.sendEmailVerification()
    .catch(err => console.error('Error sending verification email: ', err))
  }

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form-horizontal fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="Facility Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "facilityName"
                )}`}
                name="facilityName"
                {...formik.getFieldProps("facilityName")}
              />
              {formik.touched.facilityName && formik.errors.facilityName &&
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.facilityName}
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              <input
                placeholder="Number of Gyms"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "numberOfGyms"
                )}`}
                name="numberOfGyms"
                {...formik.getFieldProps("numberOfGyms")}
              />
              {formik.touched.numberOfGyms && formik.errors.numberOfGyms && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.numberOfGyms}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="Street"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "street"
                )}`}
                name="street"
                {...formik.getFieldProps("street")}
              />
              {formik.touched.street && formik.errors.street && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.street}</div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <input
                placeholder="City"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "city"
                )}`}
                name="city"
                {...formik.getFieldProps("city")}
              />
              {formik.touched.city && formik.errors.city && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.city}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="State"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "state"
                )}`}
                name="street"
                {...formik.getFieldProps("state")}
              />
              {formik.touched.state && formik.errors.state && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.state}</div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <input
                placeholder="Current Day Pass Price"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "curDayPassPrice"
                )}`}
                name="curDayPassPrice"
                {...formik.getFieldProps("curDayPassPrice")}
              />
              {formik.touched.curDayPassPrice && formik.errors.curDayPassPrice && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.curDayPassPrice}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <hr />
        <label className="mb-5 font-weight-600">
          Owner / Authorized Representative Contact Information
        </label>

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="First name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "firstName"
                )}`}
                name="firstName"
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstName}</div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <input
                placeholder="Last name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "lastName"
                )}`}
                name="firstName"
                {...formik.getFieldProps("lastName")}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lastName}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end: Fullname */}

        {/* begin: Email & phoneNumber */}
        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-4 px-5 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <input
                placeholder="Phone Number"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "phoneNumber"
                )}`}
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.phoneNumber}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* begin: Password & Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmPassword"
                )}`}
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmPassword}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="Referal Code"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("referalCode")}`}
                name="referalCode"
                {...formik.getFieldProps("referalCode")}
              />
              {formik.touched.referalCode && formik.errors.referalCode && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.referalCode}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms &&
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          }
        </div> */}
        {/* end: Terms and Conditions */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login" 
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Cancel
          </Link>
        </div>
      </form>

      <hr />
      <div className="mt-5 mb-15 mb-lg-0 flex-column-auto text-center justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          Already have an account?
        </span>
        <Link
          to="/auth/login"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Login!
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(withRouter(Registration));

import React, { useState, useContext, useEffect } from "react";
import { Sidebar } from "./../Sidebar/Sidebar";
import { UsersList } from "./UsersList";
import { Layout } from './../Layout/Layout';

export const Users = (props) => {
    return (
        <Layout>
            <div>
                <Sidebar />
                <div
                    id="kt_content"
                    className={`content  d-flex flex-column flex-column-fluid`}
                >
                    <UsersList />
                </div>
            </div>
        </Layout>
    )
}
import React from "react";
import {Link} from "react-router-dom";
import { Header } from "./../Header/Header";
import { Footer } from "./../Footer/Footer";


export const GymPage = () => {
  return (
    <>
      <Header currentPage="gym"/>

      <main>
        {/* <!-- Slider Area Start--> */}
        <div class="slider-area ">
          <div class="slider-active">
            <div class="single-slider slider-height slider-padding sky-blue d-flex align-items-center">
              <div class="container">
                <div class="row d-flex align-items-center">
                  <div class="col-lg-6 col-md-9 ">

                    <div class="hero__caption">                      
                      <h1 data-animation="fadeInUp" data-delay=".6s">
                        PASSIT
                      </h1>
                      <span data-animation="fadeInUp" data-delay=".4s">
                        The only application that makes your gym more money without risk, cost, or opportunity cost.
                      </span>
                      {/* <p data-animation="fadeInUp" data-delay=".8s">
                        Access any gym around you on demand
                      </p> */}

                      <div class="slider-btns">
                        <div class="app-btn"  data-animation="fadeInLeft" data-delay="1.0s">
                          <Link to="/auth/registration"
                            type="button"
                            className="btn btn-lg btn-light-primary font-weight-bold px-9 py-4"
                          >
                            Sign Up Now
                          </Link>
                        </div>
                      </div>

                    </div>

                  </div>
                  <div class="col-lg-6">
                    <div
                      class="hero__img d-none d-lg-block f-right"
                      data-animation="fadeInRight"
                      data-delay="1s"
                    >
                      <img src="assets/img/passit-app-transparent.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Slider Area End */}

        {/* <div class="slider-area ">
          <div class="section-padding1 pb-5">
            <div class="single-slider slider-height slider-padding d-flex align-items-center">
              <div class="container">
                <div class="row d-flex align-items-center">
                  <div class="col-md-8">

                    <div class="hero__caption">
                      <h1 style={{fontSize: '25px'}}>
                        Passit is the first ever digital application that allows fitness
                        facilities to make more money without compromising their business model.
                      </h1>

                      <span>We all know the age old problem:</span>

                      <h5 className="mb-0">People either forgo a gym membership as the hurdles to get one are too high</h5>

                      <p className="mb-5" style={{fontSize: '14px'}}>
                        -This is intentionally caused by the gym to protect their business model. We get that!
                      </p>

                      <span>OR</span>

                      <h5>
                        A person gets a gym membership and thats often the end of the story. <br/> <br/>
                        You know make x$ / mo from said person and its rare they spend money on training,
                        or physical items, and then they stop spending money at all other gyms.
                      </h5>

                      <br/><br/>

                      <h3>
                        What if there was a way we could gym all un-gymed people and get your current members to spend more money?
                      </h3>

                      <h1>WE CAN!</h1>

                      <p>Passit does this with its exclusive verification algorithm that only offers discounts on memberships to those who have and maintain an active membership at a comparable facility so membership continue to spend money working out with friends, working out across town, or when traveling.</p>

                      <p>Additionally un-gymed people for the first time ever spend money with a flexible pricing system that insures your facility is not taken advantage of, member retention stays at an all time high, are your facilities remains a premium service and value; not being found on those discount or overflow apps.</p>

                      
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section className="section-padding1 bg-green-gradient">
          <div className="container pt-30">
            {/* Section Tittle  */}
            <div className="row">
              <div className="col-md-9">
                <div className="section-tittle">
                  <h2>Make more money without risk or cost!</h2>
                </div>

                <h1 >THE AGE OLD PROBLEM:</h1>

                <h4>
                  -Gyms make 90% of their revenue from auto bill memberships. <br/>
                  -Therefore they generally have sign up and cancellation fees, contracts, and highly day rates. <br/>
                  -This is smart of the gym as it protects their revenue. However creates 2 major problems that inhibit all gyms from scaling their revenues.
                </h4>
                <br/>
                
                <h4>Problem 1: People either forgo a gym membership or cancel due to these intentional pricing structures.</h4>
                <br/>
                <h1>OR</h1>
                <br/>
                <h4>Problem 2: A person gets a gym membership and that's often the end of the story</h4>
                <br/>

                <h1>WAIT!? How is this a problem?</h1>

                <h4>Simple answer: You just capped your revenue purposefully in order to protect your current autobill revenue</h4>
                <br/>
                
                <h1>HOW?</h1>
                <h4> 
                  -You make x$ /mo from a member <br/>
                  -Less than 10% of revenue comes from auxiliary services or products. <br/>
                  -Less than 5% of people own more than one gym membership <br/>
                  -Average active members purchase 1 or less guest passes per year
                </h4> 
                <br/>               

                <h1>Conclusion:</h1>
                <h4>Once a member revenue becomes capped to insure they maintain membership.</h4>          
                
                <br/><br/>

              </div>
            </div>
          </div>
        </section>

        <section className="section-padding1">
          <div className="container pt-30">
            {/* Section Tittle  */}
            <div className="row">
              <div className="col-md-9">
                <div className="section-tittle mb-15">
                  <h2>The Question</h2>
                </div>

                <h1>
                  How can we get more un-gymed people spending money at your facility?<br/>
                  What if your current members spent more money? <br/>
                  What if other gyms member spent money at your gym? <br/><br/>
                  WITHOUT RISKING cancellations or lowering member sign ups?
                </h1>

                <br/><br/>

                <div className="section-tittle mb-15">
                  <h2>The Solution</h2>
                </div>
                
                <h4>
                  <b>PASSIT</b> does this with its exclusive verification algorithm that only offers large discounts on day passes or unlimited monthly plans to those who have and maintain an active membership. <br/><br/>
                  The most comprehensive dynamic pricing algorithm that gets you paid while protecting you.
                </h4>
                <br/><br/>

                <h1>The Result:</h1>
                <h4>
                  You won't lose memberships for the first time ever trying to attract new revenue streams and you'll actually grow and retain memberships as your gym will have the valuable perk of being a gym that validates users in app to all other gyms world wide. <br/><br/>

                  Additionally un-gymed people for the first time ever will begin to spend money with a flexible pricing system that insures your facility is not taken advantage of. <br/><br/>

                  Your facilities remain the premium service and value that thet are; since our app is not a discount app like the competition. We don' offer flat discounts. Keeping your price and value high.
                </h4>
                <br/>

                <h1>Don't worry: We continually update and validate that memberships are and stay active each month!</h1>
                        
                <br/><br/>

                <div class="slider-btns">
                  <div class="app-btn"  data-animation="fadeInLeft" data-delay="1.0s">
                    <Link to="/auth/registration" 
                      type="button"
                      className="btn btn-lg btn-light-primary font-weight-bold px-9 py-4"
                    >
                      Sign up today to start making your gym more money!
                    </Link>
                  </div>
                </div>
                <br/><br/>

              </div>
            </div>
          </div>
        </section>

        {/* <!-- How it works --> */}
        <section
          class="best-pricing pricing-padding"
          data-background="assets/img/gallery/best_pricingbg.jpg"
        >
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 col-md-8">
                <div class="section-tittle section-tittle2 text-center">
                  <h2>How It Works!</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="pricing-card-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="single-card mb-30">
                  <div class="card-top">
                    <span>User Experience</span>
                    {/* <h4>
                      $05 <span>/ month</span>
                    </h4> */}
                  </div>
                  <div class="card-bottom">
                    <ul>
                      <li>1. User downloads Passit</li>
                      <li>2. User creates profile</li>
                      <li>3. User willing uploads all active memberships</li>
                      <li className="pl-5">-this puts them into an exclusive pricing tier</li>
                      <li className="pl-5">-only works for facilities in our network</li>
                      <li className="pl-5">-validated on going month over month to insure membership retention and growth</li>
                      <li>4. User finds gyms near by in our map search</li>
                      <li>5. User buys day pass or monthly unlimited plan</li>
                      <li>6. User checks in with phone (open app click wallet, select pass, show pass to front desk attendee whom clicks to redeem and clicks confirmation to redeem)</li>
                    </ul>
                    *If user phone dies their pass can be found or redeemed from the gyms web portal on <a href="https://www.getpassit.com">getpassit.com</a>
                    {/* <a href="" class="btn card-btn1">Get Started</a> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-card mb-30">
                  <div class="card-top">
                    <span>Gym Experience</span>
                  </div>
                  <div class="card-bottom">
                    <ul>
                      <li>1. Apply to join our network</li>
                      <li>2. Get approved </li>
                      <li>3. Create your profile in app in 15 minutes or less (multi location and tiered management access available)</li>
                      <li>4. Gym Signs Passit partnership agree (simply authorized use of your facility, Passit to offer passes to your facility, cancelation terms, payment terms)</li>
                      <li>5. Get approved</li>
                      <li>6. Launch your location(s) in app</li>
                      <li>7. Print: How to check-in Passit Users (single page to be left at front desk or emailed to staff (no hardware or software integration required))</li>
                      <li>8. Print & Place Passit signage (available in admin panel, for in gym use)</li>
                      <li>9. Use Passit to scale your business (Email members about Passit App Partnership to make money faster!)</li>
                      <li></li>
                    </ul>

                    -We offer admin access to your validated members using Passit to directly offer them buddy passes to help promote and grow your business. So we encourage you to market to your members to use our platform to scale your business.

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* How it works End */}


        <section class="service-area sky-blue section-padding2">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-8">
                <div class="section-tittle mb-5 text-center">
                  <h2>It's That Simple!</h2>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-md-8">
                <h1>No: Sign up fees, monthly fees, hardware fees, software fees!</h1><br/>

                <h3>EVER! When you sign up early as pre-launch partner!</h3><br/>

                <h3>We simply retain a % of the net revenue we make your business.</h3><br/>

                <h1>The Sooner you partner with Passit the better your rate and the longer its locked in.</h1> <br/>

                <h3>Transform your revenue with no cost!</h3>

                <br/><br/><br/>

                <center>
                <Link to="/auth/registration" type="button" className="btn btn-lg btn-light-primary font-weight-bold">
                  Sign Up Today
                </Link>
                </center>
              </div>
            </div>           

          </div>
        </section>


      </main>

      <Footer />
    </>
  )
};

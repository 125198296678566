import React, {Component, useEffect} from "react";
import {Redirect} from "react-router-dom";
import { LayoutSplashScreen } from "./../MetronicSplashScreen/MetronicSplashScreen";

export const Logout = (props) => {

  useEffect( () => {
    props.logout()
  }, [])

  const { hasAuthToken } = this.props;
  
  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}



// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useContext, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "./../../helpers";
import * as uiHelpers from "./../../helpers/UserHelpers";
import ActionsColumnFormatter from "./../../helpers/ActionsColumnFormatter";
import { Pagination } from "./../Layout/pagination/Pagination";

export const UserTable = ({users, isLoading}) => {


  
 

  // const authUser = useFirebaseAuth();
  
  // useEffect( () => {
  //   dispatch( { type: 'LOGINUSER', payload: authUser}) 

  //   if (authUser !== null) {
  //     localStorage.setItem('email', authUser.email)
  //   }

  //   return () => {
  //     console.log(appContext.state, 'cha', authUser)
  //   }
    
  // }, [authUser])
  // const currentUser = appContext.;

  // useEffect( () => {

  //   console.log(state, 'ha')
  //   // clear selections list
  //   // userProps.setIds([]);
  //   // // server call by queryParams
  //   // dispatch(actions.fetchUser(userProps.queryParams));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   console.log('yoolo', state.currentUser);


  //   const getGyms = async (uid) => {
  //     const gyms = await firebase.firestore().collection('gyms')
  //     .where('admin', '==', uid)
  //     .get()
      
  //     if (gyms.empty) {
  //       return {};
  //     }

  //     console.log(gyms)
  //     console.log(gyms.docs[0].id)
  //     console.log(gyms.docs[0].data());

  //     if (gyms.docs.length > 0) {
  //       getRequest(`/getCompanyUsers?companyId=${gyms.docs[0].id}`)
  //       .then(response => response.json())
  //   .then(body => {
  //     console.log(body, 'ya')
  //   })
  //     }
  //     // gyms.forEach( doc => {
  //     //   doc.dat
  //     // })
  //   }

  //   // console.log('hmm', Object.keys(state.currentUser).length)
  //   // if (Object.keys(state.currentUser).length > 0) {
  //   //   console.log('hit')
  //   //   getGyms(currentUser.uid)
      
  //   //   // console.log('yup', gyms)
  //   // }
    
    
  //   // .then(response => response.json())
  //   // .then(body => {
  //   //   console.log(body, 'ya')
  //   // })
  //   console.log(state)

  // });
  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "firstname",
      text: "Firstname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lastname",
      text: "Lastname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "username",
      text: "Username",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "telephone",
      text: "Telephone",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.TypeColumnFormatter,
    // },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditCustomerDialog: userProps.openEditCustomerDialog,
    //     openDeleteCustomerDialog: userProps.openDeleteCustomerDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: users.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    // sizePerPage: userProps.queryParams.pageSize,
    // page: userProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <div></div>
            <Pagination
              isLoading={isLoading}
              paginationProps={paginationProps}
            >
               <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="email"
                data={users}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                // onTableChange={getHandlerTableChange(
                //   userProps.setQueryParams
                // )}
                // selectRow={getSelectRow({
                //   users,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={users} />
                <NoRecordsFoundMessage entities={users} />
              </BootstrapTable> 
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import firebase from "./../../services/firebase";
import { useForm } from "react-hook-form";
import { AppContext } from './../../contexts/AppContext';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const Login = (props) => {

  const { state, dispatch } = useContext(AppContext);
  const { register, handleSubmit, errors, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const [, setSubmitting] = useState(false);
  const [status, setStatus] = useState(false)


  const { intl } = props;
  const { history } = props;

  const onSubmit = async (data) => {
    enableLoading();
    try {
      const result = await firebase.auth().signInWithEmailAndPassword(data.email, data.password)

      console.log('ha', data, result, result.user.id, result.user.uid)
      if (result) {
        // if (!result.user.emailVerified) {
        //   await firebase.auth().signOut();
        //   setStatus(
        //     intl.formatMessage({
        //       id: "AUTH.VERIFY.EMAIL",
        //     })
        //   );

        //   // if (user.status === 'deactivated') {

        //   // }
        // } 

        
        const userRef = await firebase.firestore().collection('users').doc(result.user.uid).get();
        const user = userRef.data()

        console.log(user);
        // console.log(user.data());
        // console.log(user.get())

        dispatch({ type: 'LOGINUSER', payload: user });

        localStorage.setItem('email', user.email);

        history.push("/users")

      } 
    } catch (err) {
      console.log(err.code)

      disableLoading();
      setSubmitting(false);

      if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      } else {
        setStatus(
          intl.formatMessage({
            id: "SERVER.ERROR",
          })
        );
      }
    }
  }


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const isDirty = (field) => {
    if (formState.touched[field]) {
      return true;
    }

    return false;
  }

  const getInputClasses = (field) => {
    if (isDirty(field) && formState.errors[field]) {
      return "is-invalid";
    }

    if (isDirty(field) && !formState.errors[field]) {
      return "is-valid";
    }

    return "";
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Enter your username and password</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form onSubmit={handleSubmit(onSubmit)} className="form fv-plugins-bootstrap fv-plugins-framework">
        {status &&
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{status}</div>
            {/* <FormattedMessage id={status} /> */}
          </div>
        }

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            ref={register({ required: true })}
            className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("email")}`}
            name="email"
          />
          {errors.email &&
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.email}</div>
            </div>
          }
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            ref={register({ required: true })}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
            name="password"
          />
          {errors.password &&
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.password}</div>
            </div>
          }
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            // disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}

      <hr /> {/*Make OR centered in hr tag*/}
      <div className="mt-5 mb-15 mb-lg-0 flex-column-auto text-center justify-content-center py-5 px-10">
        <button onClick={() => { console.log('Sign in with Google') }} >
          <img
            alt="Sign in with Google"
            className="max-h-70px"
            src="/media/google_signin_buttons/1x/btn_google_signin_light_normal_web.png"
          />
        </button>
      </div>

      <hr />
      <div className="mt-5 mb-15 mb-lg-0 flex-column-auto text-center justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
        <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
      </div>

    </div>
  );
}

export default injectIntl((Login));

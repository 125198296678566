import { useEffect, useState, useContext } from "react";
import firebase from './../services/firebase';
import { AppContext } from './../contexts/AppContext';


export const useFirebaseAuth = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() =>{
       const unlisten = firebase.auth().onAuthStateChanged(
          authUser => {
            authUser
              ? setAuthUser(authUser.toJSON())
              : setAuthUser(null);
          },
       );
       return () => {
           unlisten();
       }
    }, []);

    return authUser
}

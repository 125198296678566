import React from "react";
import { Link } from "react-router-dom";
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { CountDownTimer } from './CountDownTimer';

export const HomePage = () => {
  return (
    <>
      <Header currentPage="home" />

      <main>
        {/* <!-- Slider Area Start--> */}
        <div className="slider-area">
          <div className="slider-active">
            <div className="single-slider slider-height slider-padding sky-blue d-flex align-items-center">
            
              <div className="container">
              
                <div className="row d-flex align-items-center">
                  <div className="col-md-8">
                                                         
                    <h1 className="display-1">Coming Soon</h1> 
                    <CountDownTimer />                  

                    <div className="hero__caption">
                      <span data-animation="fadeInUp" data-delay=".4s">
                        Exercise ANYWHERE with
                      </span>
                      <h1 data-animation="fadeInUp" data-delay=".6s">
                        PASSIT
                      </h1>
                      <p data-animation="fadeInUp" data-delay=".8s">
                        Via flexible digital day passes OR unlimited options
                      </p>

                      <div className="mb-70 slider-btns">
                        <p>Download app today for digital access:</p>
                        <div className="app-btn" data-animation="fadeInLeft" data-delay="1.0s">
                          <a href="#" className="app-btn1 mr-5 mb-5">
                            <img src="assets/img/shape/app_btn1.png" alt="" />
                          </a>
                          <a href="#" className="app-btn2">
                            <img src="assets/img/shape/app_btn2.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-4">
                    <div
                      className="hero__img d-none d-lg-block f-right"
                      data-animation="fadeInRight"
                      data-delay="1s"
                    >
                      <img src="assets/img/passit-app-transparent.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Slider Area End */}

        <section className="section-padding1 bg-green-secondary">
          <div className="container pt-30">
            {/* Section Tittle */}
            <div className="row">
              <div className="col-md-8">
                <div className="section-tittle">
                  <h2>Access any fitness facility citywide or nationwide with PASSIT</h2>
                </div>
                <h1 className="mb-15">
                  We are the first ever application that allows you to use your current memberships to get exclusive pricing discounts
                </h1>

                <div className="mb-15">
                  <h2>Have an active fitness membership?</h2>
                  <h5>Upload membership in app- prices automatically adjusts! Up to 70% off</h5>
                </div>

                <div className="mb-15">
                  <h2>Don't have a membership? You still get perks!</h2>
                  <h5>-We are the easiest hassle free way to workout anywhere with transparent prices with no commitment</h5>
                  <h5>-Convenient day passes OR monthly unlimited plans with no commitment</h5>
                </div>

                <h3>Download now:</h3>
                <div className="app-btn mb-15">
                  <a href="#" className="app-btn1 mb-5 mr-5">
                    <img src="assets/img/shape/app_btn1.png" alt="" />
                  </a>
                  <a href="#" className="app-btn2">
                    <img src="assets/img/shape/app_btn2.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Best Features Start */}
        <section className="best-features-area section-padding">
          <div className="container">
            {/* <div className="row">
              <div className="col-xl-8 col-lg-10"> */}
                {/* <!-- Section Tittle --> */}
                <div className="row">
                  <div className="col-lg-10 col-md-10">
                    <div className="section-tittle">
                      <h2>Some of the best features of Our App!</h2>
                    </div>
                  </div>
                </div>
                {/* <!-- Section caption --> */}
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fas fa-id-card-alt"></span>
                      </div>
                      <div className="features-caption">
                        <h3>Day OR Monthly Passes</h3>
                        <p>
                          Daily passes available for the casual users OR flexible monthly plans: Specific gym, citywide, and nationwide monthly unlimited plans.<br/>
                          Cancel anytime, upgrade/downgrade anytime, no sign up or cancelation fees, no annual fees.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fas fa-hand-holding-usd"></span>
                      </div>
                      <div className="features-caption">
                        <h3>Membership Rewards</h3>
                        <p>
                          Use your active fitness memberships to save up to 70% with any of our fitness partners.<br/>
                          Upload membership(s) in app, keep them active ( we validate each month).<br/>
                          Validated members get free buddy passes from their home membership to digitally share with friends.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fas fa-user-friends"></span>
                      </div>
                      <div className="features-caption">
                        <h3>User Rewards</h3>
                        <p>Give FREE Day Passes when you refer your friends to Passit and get FREE Day Passes.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fas fa-barcode"></span>
                      </div>
                      <div className="features-caption">
                        <h3>Store Membership Cards</h3>
                        <p>
                          Store any membership card with a barcode in one place.<br/>
                          We are your one app for all your memberships.<br/>
                          We allow users to save even more money leveraging their current memberships.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fas fa-info"></span>
                      </div>
                      <div className="features-caption">
                        <h3>Know Before You Go</h3>
                        <p>
                          Find local facilities of all types that have exactly what you need for the day.<br/>
                          Type and # of equipment, current traffic, amenities, classes, hours/staffed hours, training, and more!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <span className="fa fa-dumbbell"></span>
                      </div>
                      <div className="features-caption">
                        <h3>Flexible Fitness</h3>
                        <p>
                          Work out anywhere with ease.<br/>
                          We know you want to workout with friends, at more convenient locations, try different amenities, and of course when you travel.<br/>
                          We are a powerful app on your side in your city and when you travel.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div>
            </div> */}

            <br/>
            
            <h1>Download now:</h1>
            <div className="app-btn mb-15">
              <a href="#" className="app-btn1 mr-5 mb-20">
                <img src="assets/img/shape/app_btn1.png" alt="" />
              </a>
              <a href="#" className="app-btn2">
                <img src="assets/img/shape/app_btn2.png" alt="" />
              </a>
            </div>

          </div>

          {/* <div className="features-shpae d-none d-lg-block">
            <img src="assets/img/shape/best-features.png" alt="" />
          </div> */}
        </section>
        {/* Best Features End */}

        {/* Services Area Start */}
        <section className="service-area sky-blue section-padding2">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="section-tittle text-center">
                  <h2>
                    How PASSIT Works
                  </h2>
                </div>
              </div>
            </div>
            {/* <!-- Section caption --> */}
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="services-caption text-center mb-30">
                  <div className="service-icon">
                    <span className="fas fa-download"></span>
                  </div>
                  <div className="service-cap">
                    <h4>
                     <a href="javascript: void(0);">Step 1: Download, sign-in, upload memberships & SAVE!</a>
                    </h4>
                    <p className="text-left">
                      Simply download our app, create an account, verify your account, then add 1 or more active fitness memberships to save BIG and give you access to any facility in our network plus exclusive validated member perks!<br/><br/>
                      Is your gym not in our network? Upload anyways and we will attempt to validate it each month until your gym join our network. Feel free to reach out to them and encourage them to join :) <br/><br/>
                      Don't have a membership? Our app still works to give you fair transparent pricing, access, promotion, and much smaller discounts.<br/><br/>
                      At Passit we have negotiated with gyms for you, so you can choose where you workout today and save extra money by having your active membership saved in app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-caption text-center mb-30">
                  <div className="service-icon">
                    <span className="fas fa-search"></span>
                  </div>
                  <div className="service-cap">
                    <h4>
                      <a href="javascript: void(0);">Step 2: Find and Purchase </a>
                    </h4>
                    <p className="text-left">
                      Forget paper passes!<br/>
                      We allow you to buy single day use passes or an unlimited monthly option that suits your needs.<br/><br/>
                      We don't sell you what you don't need and we dont require commitment or contracts.<br/><br/>
                      The first app ever with: flexible pricing options, memberships used for discounts, and user by user pricing to offer the lowest possible price.<br/><br/>
                      Our unique search and sort features make it easier than ever to find what suits your needs and know before you go.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-caption text-center mb-30">
                  <div className="service-icon">
                    <span className="fas fa-check-double"></span>
                  </div>
                  <div className="service-cap">
                    <h4>
                      <a href="javascript: void(0);">Step 3: 2 Button Simple Check-In</a>
                    </h4>
                    <p className="text-left">
                      When you get to the gym, we make it easy.<br/><br/>
                      Day pass / Monthly plans: Simply open app, open app to pass, show to front desk attendant who clicks to redeem in app.<br/><br/>
                      If you're phone dies the front desk attendant can check you in from their computer. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Services Area End --> */}

        <section className="section-padding1">
          <div className="container pt-30">
            {/* Section Tittle style={{fontSize: '28px'}}  */}
            <div className="row">
              <div className="col-md-8">
                <div className="section-tittle">
                  <h2>Passit: A Stand Alone App</h2>
                </div>
                <h1>Passit was built from the ground up as a stand alone app.</h1>
                <h4 className="mb-15">Most apps take over markets city by city, state by state, country by country</h4>

                <h2>We take a different approach!</h2>
                <h4 className="mb-15">
                  Launching nationwide because even if your gym is not a partner to validate you to our lowest priced tier or no gyms are near you. <br/><br/>
                  Our partner facilities and their members still benefit even at small scale. We allow them a digital way to store their pass, digitally share buddy passes with their friends, and access to gyms when they travel.<br/><br/>
                  So even with 1 gym in a given city our app works.<br/><br/>

                  So refer your gym to our network, join Passit now so we on-board more gyms in your area, and be patient :)<br/><br/>

                  Another reason we offer flexible pricing options: density of gyms will vary by city short term.
                </h4>
                
                <h3>Download Passit now to get digital access to any partner gym:</h3>
                <div className="app-btn mb-15">
                  <a href="#" className="app-btn1 mb-5 mr-5">
                    <img src="assets/img/shape/app_btn1.png" alt="" />
                  </a>
                  <a href="#" className="app-btn2">
                    <img src="assets/img/shape/app_btn2.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Available App Start--> */}
        <div className="available-app-area">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-5 col-lg-6">
                <div className="app-caption">
                  <div className="section-tittle section-tittle3">
                    <h2 className="text-body">Passit available on any Apple or Android device now</h2>
                    <h3>Download today to workout anywhere:</h3>
                    <div className="app-btn">
                      <a href="#" className="app-btn1">
                        <img src="assets/img/shape/app_btn1.png" alt="" />
                      </a>
                      <a href="#" className="app-btn2">
                        <img src="assets/img/shape/app_btn2.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="app-img">
                  <img src="assets/img/shape/available-app.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Shape --> */}
          <div className="app-shape">
            <img
              src="assets/img/shape/app-shape-top.png"
              alt=""
              className="app-shape-top heartbeat d-none d-lg-block"
            />
            <img
              src="assets/img/shape/app-shape-left.png"
              alt=""
              className="app-shape-left d-none d-xl-block"
            />
            <img
              src="assets/img/shape/app-shape-right.png"
              alt=""
              className="app-shape-right bounce-animate "
            />
          </div>
        </div>
        {/* <!-- Available App End--> */}

        {/* <!-- Applic App Start --> */}
        {/* <div className="applic-apps section-padding2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-8">
                <div className="single-cases-info mb-30">
                  <h3>
                    Application Apps
                    <br /> Screenshot
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consecadipiscing elit, sed do
                    eiusmod tempor incididunt ut ore et dolore magna aliqua.
                    Quis ipsum suspendisse gravida. Risus commodo viverra
                    maecenasan lacus vel facilisis.{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-7">
                <div className="app-active owl-carousel">
                  <div className="single-cases-img">
                    <img src="assets/img/gallery/App1.png" alt="" />
                  </div>
                  <div className="single-cases-img">
                    <img src="assets/img/gallery/App2.png" alt="" />
                  </div>
                  <div className="single-cases-img">
                    <img src="assets/img/gallery/App3.png" alt="" />
                  </div>
                  <div className="single-cases-img">
                    <img src="assets/img/gallery/App2.png" alt="" />
                  </div>
                  <div className="single-cases-img">
                    <img src="assets/img/gallery/App1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Applic App End --> */}

        {/* <!-- Best Pricing & Pricing Card Start --> */}
        {/* <section
          className="best-pricing pricing-padding"
          data-background="assets/img/gallery/best_pricingbg.jpg"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="section-tittle section-tittle2 text-center">
                  <h2>Choose Your Very Best Pricing Plan.</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="pricing-card-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <span>2 Years</span>
                    <h4>
                      $05 <span>/ month</span>
                    </h4>
                  </div>
                  <div className="card-bottom">
                    <ul>
                      <li>Increase traffic 50%</li>
                      <li>E-mail support</li>
                      <li>10 Free Optimization</li>
                      <li>24/7 support</li>
                    </ul>
                    <a href="services.html" className="btn card-btn1">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-card  text-center mb-30">
                  <div className="card-top">
                    <span>2 Years</span>
                    <h4>
                      $05 <span>/ month</span>
                    </h4>
                  </div>
                  <div className="card-bottom">
                    <ul>
                      <li>Increase traffic 50%</li>
                      <li>E-mail support</li>
                      <li>10 Free Optimization</li>
                      <li>24/7 support</li>
                    </ul>
                    <a href="services.html" className="btn card-btn1">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-card text-center mb-30">
                  <div className="card-top">
                    <span>2 Years</span>
                    <h4>
                      $05 <span>/ month</span>
                    </h4>
                  </div>
                  <div className="card-bottom">
                    <ul>
                      <li>Increase traffic 50%</li>
                      <li>E-mail support</li>
                      <li>10 Free Optimization</li>
                      <li>24/7 support</li>
                    </ul>
                    <a href="services.html" className="btn card-btn1">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Best Pricing & Pricing Card End --> */}

        {/*  Our Customer Start */}
        {/* <div className="our-customer section-padd-top30">
          <div className="container-fluid">
            <div className="our-customer-wrapper">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8">
                  <div className="section-tittle text-center">
                    <h2>
                      What Our Customers
                      <br /> Have to Say
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="customar-active dot-style d-flex dot-style">
                    <div className="single-customer mb-100">
                      <div className="what-img">
                        <img src="assets/img/shape/man1.png" alt="" />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="#">
                            Welcome To The Best Model Winner Contest
                          </a>
                        </h4>
                        <p>
                          Utenim ad minim veniam quisnostrud exercitation
                          ullamcolabor nisiut aliquip ex ea commodo consequat
                          duis aute irure dolor in represse.
                        </p>
                      </div>
                    </div>

                    <div className="single-customer mb-100">
                      <div className="what-img">
                        <img src="assets/img/shape/man2.png" alt="" />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="#">
                            Welcome To The Best Model Winner Contest
                          </a>
                        </h4>
                        <p>
                          Utenim ad minim veniam quisnostrud exercitation
                          ullamcolabor nisiut aliquip ex ea commodo consequat
                          duis aute irure dolor in represse.
                        </p>
                      </div>
                    </div>

                    <div className="single-customer mb-100">
                      <div className="what-img">
                        <img src="assets/img/shape/man3.png" alt="" />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="#">
                            Welcome To The Best Model Winner Contest
                          </a>
                        </h4>
                        <p>
                          Utenim ad minim veniam quisnostrud exercitation
                          ullamcolabor nisiut aliquip ex ea commodo consequat
                          duis aute irure dolor in represse.
                        </p>
                      </div>
                    </div>

                    <div className="single-customer mb-100">
                      <div className="what-img">
                        <img src="assets/img/shape/man2.png" alt="" />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="#">
                            Welcome To The Best Model Winner Contest
                          </a>
                        </h4>
                        <p>
                          Utenim ad minim veniam quisnostrud exercitation
                          ullamcolabor nisiut aliquip ex ea commodo consequat
                          duis aute irure dolor in represse.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Our Customer End --> */}

        {/* <!-- Say Something Start --> */}
        {/* <div className="say-something-aera pt-90 pb-90 fix">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="offset-xl-1 offset-lg-1 col-xl-5 col-lg-5">
                <div className="say-something-cap">
                  <h2>Say Hello To The Collaboration Hub.</h2>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3">
                <div className="say-btn">
                  <a href="#" className="btn radius-btn">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="say-shape">
            <img
              src="assets/img/shape/say-shape-left.png"
              alt=""
              className="say-shape1 rotateme d-none d-xl-block"
            />
            <img
              src="assets/img/shape/say-shape-right.png"
              alt=""
              className="say-shape2 d-none d-lg-block"
            />
          </div>
        </div> */}
        {/* <!-- Say Something End --> */}

      </main>

      <Footer />
    </>
  );
}

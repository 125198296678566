import React, { createContext, useReducer, useContext } from 'react';
import {appReducer} from './../reducers/AppReducer'

const initialState = { darkmode: false, currentUser: {}  }
const AppContext = createContext(initialState)

const { Provider } = AppContext

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { AppContext, AppProvider }
export const useAppContext = () => useContext(AppContext);

import firebase from "./firebase";
const url = 'https://us-central1-passit-cc317.cloudfunctions.net';

export async function postRequest(path, payload) {  
  const token = await firebase.auth().currentUser.getIdToken(true);
  return fetch(`${url}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(payload),
  })
}

export async function getRequest(path) {
  const token = await firebase.auth().currentUser.getIdToken(true);
  console.log((await firebase.auth().currentUser).toJSON()   )
  return fetch(`${url}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },    
  })
}
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Input } from "../../Layout/forms/Input";
import { Select } from "../../Layout/forms/Select";
import { DatePickerField } from "../../Layout/forms/DatePickerField";
import { postRequest } from "../../../services/api";

// Validation schema

const UserEditForm = ({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  intl,
  history,
  user,
  companyId,
  setStatus
}) => {
  const { register, handleSubmit, errors, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const [, setSubmitting] = useState(false);



  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const isDirty = (field) => {
    if (formState.touched[field]) {
      return true;
    }

    return false;
  }

  const getInputClasses = (field) => {
    if (isDirty(field) && formState.errors[field]) {
      return "is-invalid";
    }

    if (isDirty(field) && !formState.errors[field]) {
      return "is-valid";
    }

    return "";
  };


  const onSubmit = (data) => {
    console.log(data)
    enableLoading();
    postRequest(`/addCompanyUsers`, {
      "companyId": `${companyId}`,
      "user": data
    })
      .then(response => response.json())
      .then(body => {
        disableLoading();
        setStatus(
          intl.formatMessage({
            id: "USER.ADDED",
          })
        );
        onHide()
      })
  }

  return (
    <>
      <Modal.Body className="cursor-default">
        <form onSubmit={handleSubmit(onSubmit)} className="form form-label-right row">
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="First Name"
              type="text"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("firstname")}`}
              name="firstname"
            />
            {errors.firstname &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.firstname}</div>
              </div>
            }
          </div>
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="Last Name"
              type="text"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("lastname")}`}
              name="lastname"
            />
            {errors.lastname &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.lastname}</div>
              </div>
            }
          </div>
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="Email"
              type="email"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("email")}`}
              name="email"
            />
            {errors.email &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.email}</div>
              </div>
            }
          </div>
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="Password"
              type="password"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("password")}`}
              name="password"
            />
            {errors.password &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.password}</div>
              </div>
            }
          </div>
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="Username"
              type="text"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("username")}`}
              name="username"
            />
            {errors.username &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.username}</div>
              </div>
            }
          </div>
          <div className="form-group fv-plugins-icon-container col-lg-6">
            <input
              placeholder="Telephone"
              type="phone"
              ref={register({ required: true })}
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses("telephone")}`}
              name="telephone"
            />
            {errors.telephone &&
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.telephone}</div>
              </div>
            }
          </div>
          {/* Last Name */}
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
              </button>
        <> </>
        <button
          type="submit"
          // onClick={() => handleSubmit()}
          className="btn btn-primary btn-elevate"
        >
          Save
              </button>

        </form>
      </Modal.Body>
      
    </>
  );
}

export default injectIntl((UserEditForm));